import React, { useMemo } from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';


const LineItemShippingFeeMaybe = props => {
  const { lineItems, intl, customShippingPrice, transactionShippingPrice } = props;

  const shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );
  const numberedCustomShippingPrice =  Number(customShippingPrice)

  const price = useMemo(() => {
    if(transactionShippingPrice) {
      return formatMoney(intl, new Money(transactionShippingPrice, 'GBP'))
    }

    if(numberedCustomShippingPrice) {
      return intl.formatNumber(numberedCustomShippingPrice, {
        style: 'currency',
        currency: 'GBP',
        currencyDisplay: 'symbol',
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    }

    if(shippingFeeLineItem?.lineTotal){
      return formatMoney(intl, shippingFeeLineItem?.lineTotal)
    }

    return null;


  },[transactionShippingPrice, shippingFeeLineItem?.lineTotal, numberedCustomShippingPrice])


  return price ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.shippingFee" />
      </span>
      <span className={css.itemValue}>
        {price}
      </span>
    </div>
  ) : null;
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
