import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';


const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, customShippingPrice, baseLineItem, transactionShippingPrice} = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);

  if (!processName) {
    return null;
  }

  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  const preparedToFormatShippingPrice = customShippingPrice ? Number(customShippingPrice?.replace('.', '')) : 0

  const quantity = baseLineItem?.quantity.toString()
  const unitPrice = baseLineItem?.unitPrice

  const formattedUnitPrice = transactionShippingPrice ? (unitPrice?.amount ?? 0) - (transactionShippingPrice / Number(quantity)) : 0

  const total = transactionShippingPrice ? Math.round(formattedUnitPrice) * Number(quantity) + transactionShippingPrice : preparedToFormatShippingPrice + totalPrice?.amount

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formatMoney(intl, new Money(total, totalPrice?.currency))}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
