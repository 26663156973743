import React from 'react'
import css from './OrderBreakdown.module.css'
import { propTypes } from '../../util/types';


const LineItemVetNeeded = props => {
    const {vetNeeded} = props;
  return vetNeeded ?  (
    <>
      <hr className={css.totalDivider}/>
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>Vet Needed?</span>
        <span className={css.itemValue}>{vetNeeded}</span>
      </div>
    </>
  ): null
} 

export default LineItemVetNeeded;
